<template>
    <div class="container-lg gochi-hand-regular">
        <div class="row d-flex flex-row text-center m-0 p-2 p-md-0 cat-card">
            <div class="d-none d-md-block col-md-2 d-flex justify-content-start p-3"><img src="@/assets/cocktail/cocktail_avatar.png" class="img-fluid object-fit-contain"></div>
            <div class="col-12 col-md-8 d-flex align-items-center justify-content-center d-flex flex-column">
                <p class="fs-1">COCKTAIL</p>
                <img src="@/assets/cocktail/cocktail_avatar_3.png" class="img-fluid object-fit-contain w-50 ">
            </div>
            <div class="d-none d-md-block col-md-2 d-flex justify-content-end align-content-center p-3"><img src="@/assets/cocktail/cocktail_avatar_2.png" class="img-fluid object-fit-contain"></div>
        </div>

        <div class="line_container"><div class="line my-5"></div></div>

        <Waterfall :list="cockTailList" :breakpoints="breakpoints">
            <template #item="{item, url, index}">
                <div class="card" id="cocktail-pic" @click="openModal(item)">
                    <LazyImg :url=item.img class="card-img-top"/>
                    <div class="card-body ">
                        <h5 class="card-title gochi-hand-regular fs-4">{{ item.name }}</h5>
                        <p class="text-muted text-center my-3 recipe">{{ item.recipe }}</p>
                        <p class="text-muted mb-0"><i class="bi bi-geo-alt me-1"></i>{{ item.location }}</p>
                    </div>
                    <div class="card-footer text-body-secondary text-center ">
                        {{ item.date }}
                    </div>
                </div>
            </template>
        </Waterfall>

        <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modal">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="card w-100 border-0 rounded-3 p-0 m-0">
                    <div class="row rounded-3 p-0 m-0">
                        <div class="col-6 rounded-3 p-0 m-0"><img :src=cocktailItem.img class="img-fluid rounded-start-3" /></div>
                        <div class="col-6  p-3 d-flex flex-column flex-content-center justify-content-around">
                            <h5 class="card-title gochi-hand-regular fs-4">{{ cocktailItem.name }}</h5>
                            <p class="text-muted text-center my-3 recipe m-auto">{{ cocktailItem.recipe }}</p>
                            <div>
                                <p class="text-muted mb-0"><i class="bi bi-geo-alt me-1"></i>{{ cocktailItem.location }}</p>
                                <p class="text-muted mb-0"><i class="bi bi-calendar-date me-1"></i>{{ cocktailItem.date }}</p>
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { LazyImg, Waterfall } from 'vue-waterfall-plugin'
import 'vue-waterfall-plugin/dist/style.css'

export default {

    components: {
        Waterfall,LazyImg
    },
    data() {
        return {
            cockTailList: [
                {name:"巨峰白兰地Soda",recipe:"白兰地 / 葡萄浸泡 / 气泡水",img:require('@/assets/cocktail/250122_2.jpg'),date:"2025-01-22",location:"似酒likejuju, 广州市天河区天河南一路90号104铺"},
                {name:"霉霉俱乐部",recipe:"树莓朗姆酒 / 黑莓利口酒 / 蔓越莓汁 / 树莓果茸 / 柠檬汁",img:require('@/assets/cocktail/250122_1.jpg'),date:"2025-01-22",location:"龟岛 Tortuga, 广州市天河区六运七街6号102铺"},

                {name:"贵阳酸汤",recipe:"木姜子 / 番茄 / 乌梅 / 紫苏 / 苹果 / 杜松子 / 柠檬",img:require('@/assets/cocktail/250107.jpg'),date:"2025-01-07",location:"Moment, 广州市增城区荔城街园圃路41号"},
                {name:"威士忌酸",recipe:"威士忌 / 柠檬 / 糖浆",img:require('@/assets/cocktail/241220.jpg'),date:"2024-12-20",location:"Homemade"},

                {name:"一口海洋",recipe:"紫菜 / 鲜虾 / 海盐 / 米酒 / 杜松子",img:require('@/assets/cocktail/241206_1.jpg'),date:"2024-12-06",location:"Moment, 广州市增城区荔城街园圃路41号"},
                {name:"青",recipe:"罗勒 / 青提 / 杜松子 / 红茶菌 / 康普茶",img:require('@/assets/cocktail/241206_2.jpg'),date:"2024-12-06",location:"Moment, 广州市增城区荔城街园圃路41号"},

                {name:"12月份的惊喜2.0",recipe:"海盐黄皮 / 百香果 / 茉莉花茶 / 朗姆 / 柠檬",img:require('@/assets/cocktail/241203_3.jpg'),date:"2024-12-03",location:"Moment, 广州市增城区荔城街园圃路41号"},
                {name:"半生瓜",recipe:"苦瓜 / 油柑 / 雪梨 / 柠檬 ",img:require('@/assets/cocktail/241203_4.jpg'),date:"2024-12-03",location:"Moment, 广州市增城区荔城街园圃路41号"},
                {name:"临冬",recipe:"话梅 / 大红袍茶 / 苹果醋 / 白橙 / 柠檬",img:require('@/assets/cocktail/241203_5.jpg'),date:"2024-12-03",location:"Moment, 广州市增城区荔城街园圃路41号"},

                {name:"僵尸",recipe:"朗姆 / 君度 / 菠萝汁 / 柠檬汁 / 石榴汁",img:require('@/assets/cocktail/241203_2.jpg'),date:"2024-12-03",location:"Blues, 广州市增城区荔城街岗前路28号北栋首层之四"}, //配方需要验证
                {name:"马天尼",recipe:"杜松子 / 干味美思 / 橄榄",img:require('@/assets/cocktail/241203_1.jpg'),date:"2024-12-03",location:"Blues, 广州市增城区荔城街岗前路28号北栋首层之四"},



                {name:"龙井青提小麦 / 肉桂沙华 / 甜椒孜然玻璃",recipe:"龙井风味酒 / 德式小麦风味酒 / 澄清青提汁 / 碳酸化处理; \n" +
                      "肉桂风味威士忌 / 糖 / 柠檬汁 / 无菌蛋清; \n" +
                      "甜椒风味酒 / 孜然粉 / 澄清菠萝汁;",img:require('@/assets/cocktail/241124.jpg'),date:"2024-11-24",location:"倒久 POURROOM, 广州市海珠区江南西紫金大街7号之一首层"},

                {name:"山楂威士忌酸",recipe:"自制山楂果糖 / 波本威士忌酸 / 柠檬 ",img:require('@/assets/cocktail/241123_2.jpg'),date:"2024-11-23",location:"Moment, 广州市增城区荔城街园圃路41号"},
                {name:"咖啡尼格罗尼",recipe:"/",img:require('@/assets/cocktail/241123_1.jpg'),date:"2024-11-23",location:"Moment, 广州市增城区荔城街园圃路41号"},


                {name:"金汤力",recipe:"亨利爵士金酒 / 柠檬 / 汤力水;",img:require('@/assets/cocktail/241111.jpg'),date:"2024-11-11",location:"Homemade"},

                {name:"苹果肉桂威士忌",recipe:"NFC苹果汁 / 威士忌 / 肉桂",img:require('@/assets/cocktail/241107_2.jpg'),date:"2024-11-07",location:"似酒likejuju, 广州市天河区天河南一路90号104铺"},
                {name:"汉岭青木",recipe:"苏合向右洗张裕可雅白兰地 / 雪松木低温慢煮脱糖利口酒 / 桂花香豆糖醇 / 柠檬汁",img:require('@/assets/cocktail/241107_5.jpg'),date:"2024-11-07",location:"庙前三酉, 广州市天河区天河东路75汇坊人前店F3层"},
                {name:"灵魂思考",recipe:"龙舌兰 / 青注乌龙 / 苹果 / 莳萝 / 龙胆草 / 柠檬",img:require('@/assets/cocktail/241107_4.jpg'),date:"2024-11-07",location:"三羊, 广州市天河区华强路2号富力盈丰大厦A座108铺"},
                {name:"临时抱佛脚",recipe:"朗姆 / 白玉兰乌龙 / 桂花陈酒 / 橙花水 / 柠檬 / 苏打",img:require('@/assets/cocktail/241107_3.jpg'),date:"2024-11-07",location:"三羊, 广州市天河区华强路2号富力盈丰大厦A座108铺"},

                {name:"黄色大门",recipe:"云南玫瑰洛神花老卤酒 / 荔枝甜酒 / 柠檬",img:require('@/assets/cocktail/241106.jpg'),date:"2024-11-06",location:"Moment, 广州市增城区荔城街园圃路41号"},
                {name:"观音玉2.0",recipe:"奇异果 / 铁观音茶 / 杜松子 / 香水柠檬",img:require('@/assets/cocktail/241106_2.jpg'),date:"2024-11-06",location:"Moment, 广州市增城区荔城街园圃路41号"},
                {name:"给朱利安",recipe:"抹茶 / 椰皇 / 柠檬 / 伏特加 / 荔枝利口酒 / 椰子利口酒",img:require('@/assets/cocktail/241101_2.jpg'),date:"2024-11-01",location:"Moment, 广州市增城区荔城街园圃路41号"},
                {name:"咖啡尼格罗尼",recipe:"咖啡豆浸泡尼格罗尼 / 金酒 / 红味美思 / 金巴利",img:require('@/assets/cocktail/241101.jpg'),date:"2024-11-01",location:"Moment, 广州市增城区荔城街园圃路41号"},

                {name:"兰因絮果",recipe:"金酒 / 茉莉花 / 雪莉酒 / 荔枝 / 秘鲁圣木 / 柠檬叶",img:require('@/assets/cocktail/241024_1.jpg'),date:"2024-10-24",location:"SPENT, 东莞市南城街道新城中路1号199室"},
                {name:"青出于蓝",recipe:"龙舌兰 / Pisco / 青瓜 / 莳萝 / 雪莉酒 / 青柠",img:require('@/assets/cocktail/241024_2.jpg'),date:"2024-10-24",location:"SPENT, 东莞市南城街道新城中路1号199室"},
                {name:"馥桦变奏曲",recipe:"香料朗姆酒 / 碳焙乌龙茶 / 白桦树 / 梅子 / 薰衣草",img:require('@/assets/cocktail/241024_3.jpg'),date:"2024-10-24",location:"SPENT, 东莞市南城街道新城中路1号199室"},

                {name:"新加坡司令",recipe:"金酒 / 樱桃白兰地 / 柠檬汁 / 苏打水",img:require('@/assets/cocktail/241005_1.jpg'),date:"2024-10-05",location:"Double Live, 广州市增城区荔城街观园东路北一巷3号"},
                {name:"马颈",recipe:"白兰地 / 干姜水 / 苦精",img:require('@/assets/cocktail/241005_2.jpg'),date:"2024-10-05",location:"Double Live, 广州市增城区荔城街观园东路北一巷3号"},
                
                {name:"黄",recipe:"芒果 / 凤梨 / 木姜子 / 话梅 / 朗姆酒",img:require('@/assets/cocktail/241002_1.jpg'),date:"2024-10-02",location:"Cupee, 广州市增城区荔城街荔乡路5号"},
                {name:"绿",recipe:"抹茶 / 日本柚子 / 朗姆酒",img:require('@/assets/cocktail/241002_2.jpg'),date:"2024-10-02",location:"Cupee, 广州市增城区荔城街荔乡路5号"},
                {name:"晚霞不晚",recipe:"超级西柚汁 / 阿佩罗橙酒 / 玫瑰 / 杜松子 / 柠檬",img:require('@/assets/cocktail/241002_3.jpg'),date:"2024-09-24",location:"Moment, 广州市增城区荔城街园圃路41号"},
                {name:"小王子的玫瑰2.0",recipe:"云南玫瑰洛神花老卤酒 / 荔枝甜酒 / 柠檬",img:require('@/assets/cocktail/241002_4.jpg'),date:"2024-09-24",location:"Moment, 广州市增城区荔城街园圃路41号"},
                {name:"星期八",recipe:"巨峰葡萄 / 自制红糖伯爵茶利口酒 / 黑加仑 / 柠檬",img:require('@/assets/cocktail/240924.jpg'),date:"2024-09-24",location:"Moment, 广州市增城区荔城街园圃路41号"},

                {name:"Core Philosophy & Adult Entertainment",recipe:"Gin / Elderflower / Melon / Apple Core / Jasmine Pearl Tea / Apple Vinegar / Soda; \n The Macallan 12yr Whisky / Passionfruit Wine / Apricot / Soda;",img:require('@/assets/cocktail/240622_1.jpg'),date:"2024-06-22",location:"The Public, Surrey Street, Sheffield, S1 2LG"},
                {name:"Daiq in Business",recipe:"Plantain fat-washed Appleton Rum / Honey / Allspice Wray and Nephew Tincture / Grapefruit Salt",img:require('@/assets/cocktail/240622_2.jpg'),date:"2024-06-22",location:"The Public, Surrey Street, Sheffield, S1 2LG"},
                {name:"Grape Expectation",recipe:"Barsol Pisco / Bergamot / Split Milk Clarified Grape Juice",img:require('@/assets/cocktail/240622_3.jpg'),date:"2024-06-22",location:"The Public, Surrey Street, Sheffield, S1 2LG"},

                {name:"Speed Drive & Corn Supermacy",recipe:"「Nitro Peach」double gin / Cocchi Rosa / Cardamom / Champagne Acid; \n Cuba 3yr Rum / Corn / Banana;",img:require('@/assets/cocktail/240612_1.jpg'),date:"2024-06-12",location:"The Public, Surrey Street, Sheffield, S1 2LG"},
                {name:"Sunny Disposition",recipe:"Chase Vodka / Bergamot / Blackcurrant / Pomegranate and Pink Peppercorn Shrub / Lemon / Prosecco;",img:require('@/assets/cocktail/240612_2.jpg'),date:"2024-06-12",location:"The Public, Surrey Street, Sheffield, S1 2LG"},

                {name:"NewYork Sour",recipe:"Whisky / Port / Lemon / Syrup;",img:require('@/assets/cocktail/240420.jpg'),date:"2024-04-20",location:"Homemade"},
                {name:"BLINKER",recipe:"blending rye / grapefruit / blending rye;",img:require('@/assets/cocktail/240414.jpg'),date:"2024-04-14",location:"BLINKER 70 Spring Gardens, Manchester M2 2BQ"},
                {name:"Pink Peach Tree & Golden Delicious",recipe:"The Macallan DC 12 Scotch / Peach / montillado Sherry / Bitters; \n Calvados / Honey / Lemon;",img:require('@/assets/cocktail/240413.jpg'),date:"2024-04-13",location:"SCHOFIELD's Sunlight House, 3 Little Quay Street, Manchester, M3 3JZ"},
                {name:"Blood Orange and YUZU Cosmo & Lyche and Cranberry Fizz",recipe:"Malfy arancia / YUZU sake / Lime / Vanilla / Cranberry; \n Kwai feh lyche / cranberry / lemon / prosecco;",img:require('@/assets/cocktail/240407.jpg'),date:"2024-04-07",location:"Proove, 16-17 ORCHARD SQ, SHEFFIELD, S1 2FB"},
                {name:"BLOCKBUSTER",recipe:"GIN / Fennel / Plum wine / peach / Bubbles;",img:require('@/assets/cocktail/240406.jpg'),date:"2024-04-06",location:"Murder, 36 HANWAY STREET, LONDON, W1T 1UP"},
                {name:"MANGO",recipe:"Toki Whisky / Lacto Fermented Roasted / Green Mango / Ancho Reyes Verde;",img:require('@/assets/cocktail/240405.jpg'),date:"2024-04-05",location:"SOMA, 14 Denman St, London W1D 7HJ"},
                {name:"Bee's knees",recipe:"Gin / Lemon / Honey;",img:require('@/assets/cocktail/240207.jpg'),date:"2024-02-07",location:"Homemade"},
                {name:"PALOMA & RASPBERRY",recipe:"Grapefruit / Tequila / Lime \n Raspvetty / Gin / Bubbles;",img:require('@/assets/cocktail/240131.jpg'),date:"2024-01-31",location:"PASSING FANCIES, 30 Gibb Street, The Custard Factory, B94BF "},
                {name:"Plum Together",recipe:"Roku Japanese gin / Akashi-Tai plum sake, cherry syrup / tartaric acid / plum bitters / Tartarashi chilli rim;",img:require('@/assets/cocktail/240118.jpg'),date:"2024-01-18",location:"The Public, Surrey Street, Sheffield, S1 2LG"},
                {name:"Million Dollar Bounty",recipe:"Coconut oil-washed Johnny Walker Black Label whisky / Koko Kanu rum / Giffard Crème de Cacao / Miso demerara syrup / chocolate bitters",img:require('@/assets/cocktail/231105.jpg'),date:"2023-11-05",location:"The Public, Surrey Street, Sheffield, S1 2LG"},
                {name:"Pornstart Matini",recipe:"Smirnoff vanilla vodka, passoa / pineapple juice / lime juice / passionfruit puree / sugarsyrup / served with a shot of Prosecco;",img:require('@/assets/cocktail/231117.jpg'),date:"2023-11-17",location:"Colonel Porter's Emporium, Milburn House, Dean St, Newcastle upon Tyne NE1 1LF"},
                {name:"Yoko Ob-yes",recipe:"Galangal / Agave / Genmaicha Tea / Yuzu Sake / Suze / Acid / Peated Olive Oil;",img:require('@/assets/cocktail/231223.jpg'),date:"2023-12-23",location:"The Absent Ear, Brunswick St, Glasgow G1 1TF"},
                {name:"Nola",recipe:"",img:require('@/assets/cocktail/231227.jpg'),date:"2023-12-27",location:"Nola, 39 Union St, Dundee DD1 4BS"},
                {name:"Draffens",recipe:"",img:require('@/assets/cocktail/231228.jpg'),date:"2023-12-28",location:"Draffens, Couttie's Wynd, Dundee DD1 4ET"},
                {name:"Long Island iced tea",recipe:"Vodka / Gin / Tequila / Rum / Cointreau / Lime / Cola / Syrup",img:require('@/assets/cocktail/231020.jpg'),date:"2023-10-22",location:"Homemade"},
                {name:"Ice Breaker",recipe:"Agave / Cointreau / Grapefruit / Grenadine Syrup;",img:require('@/assets/cocktail/231017.jpg'),date:"2023-10-17",location:"Homemade"},
                {name:"Whiskey sour",recipe:"Whisky / Lemon / Syrup",img:require('@/assets/cocktail/231005.jpg'),date:"2023-10-05",location:"Homemade"},

                
            ],
            breakpoints: {
                0: {
                    rowPerView: 1,
                },
                576: {
                    rowPerView: 2,
                },
                768: {
                    rowPerView: 3,
                },
                992: {
                    rowPerView: 4,
                },
                1200: {
                    rowPerView: 5,
                },
                1400: {
                    rowPerView: 5,
                }
            },
            cocktailItem:{name:"黄",recipe:"芒果 / 凤梨 / 木姜子 / 话梅 / 朗姆酒",img:require('@/assets/cocktail/241002_1.jpg'),date:"2024-10-02",location:"Cupee, 广州市增城区荔城街荔乡路5号"}
        };
    },
    methods: {
        openModal(item) {
            // Populate the modal data with the clicked item
            this.cocktailItem = { ...item };

            // Ensure the modal is shown
            const modalElement = document.getElementById('modal');
            if (modalElement) {
                const modal = new window.bootstrap.Modal(modalElement);
                modal.show();
            }
        },
    }
}
</script>

<style lang="css" scoped>


@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');

.gochi-hand-regular {
    font-family: "Gochi Hand", cursive;
    font-weight: 600;
    font-style: normal;
}

#cocktail-pic {
    transition: 0.1s;
}

#cocktail-pic:hover {
    border: 2px solid #6e6d7a;
    cursor: pointer;
}


.recipe {
    white-space: pre-line; /* 或者使用 pre-wrap */
}

.cat-card {
    background-color: #1f2534;
    color: white;
}
</style>