<template>
    <div id="HomeView" class="container-lg">
        <div id="intro" class="wow fadeIn mt-5" data-wow-duration="3s">
            <MyInfoCard></MyInfoCard>
<!--                     <van-button color="#FF6464" class="dl-btn float-end" @click="downloadResume" >下载简历</van-button>-->
        </div>

        <div class="project wow fadeIn" data-wow-duration="3s">
            <div class="row mt-5 rounded-3 g-3 "> 
                <div class="col-12 col-md-4 col-lg-3 d-flex flex-column justify-content-center">
                    <div class="card rounded-5 shadow-lg bg-white p-3" >
                        <h3><span style="color: #ff6666;" class="fw-bold">{{ $t('message.about.apps.apps') }}</span> | {{ $t('message.about.apps.coursework') }}</h3>

                        <CardWithDetail toURL='/works/eWaste'>
                            <img src="../assets/worksDetail/ewaste/ewaste.png" class="img-fluid h-100 w-100 object-fit-fill" />
                            <template v-slot:overlay>
                                <div class="d-flex flex-column">
                                    <h3>{{ $t('message.project.eWaste.title') }} </h3>
                                    <!-- <p>{{ $t('message.project.eWaste.abstract') }} </p> -->
                                </div>
                            </template>
                        </CardWithDetail>

                        <CardWithDetail toURL='/works/BirdWatching'>
                            <img src="../assets/worksDetail/birdWatching/birdWatching.png" class="img-fluid h-100 w-100 object-fit-fill" />
                            <template v-slot:overlay>
                                <div class="d-flex flex-column">
                                    <h3>{{ $t('message.project.birdWatching.title') }} </h3>
                                    <!-- <p>{{ $t('message.project.birdWatching.abstract') }} </p> -->
                                </div>
                            </template>
                        </CardWithDetail>

                        <CardWithDetail toURL='/works/TheBigWalk'>
                            <img src="../assets/worksDetail/theBigWalk/theBigWalk.png" class="img-fluid h-100 w-100 object-fit-fill" />
                            <template v-slot:overlay>
                                <div class="d-flex flex-column">
                                    <h3>{{ $t('message.project.theBigWalk.title') }} </h3>
                                    <!-- <p>{{ $t('message.project.theBigWalk.abstract') }} </p> -->
                                </div>
                                
                            </template>
                        </CardWithDetail>
                    </div>
                </div>
                <div class="col-12 col-md-8 col-lg-9 d-flex flex-column justify-content-center">
                    <div class="card rounded-5 shadow-lg bg-white p-3 ">
                        <h3><span style="color: #ff6666;" class="fw-bold">{{ $t('message.about.apps.apps') }}</span> | {{ $t('message.about.apps.dissertation') }}</h3>
                        <div class="row d-flex flex-row g-3">


                            <div class="col-12 col-md-6">
                                <CardWithDetail class="h-100" toURL='/works/HealthyExpert'>
                                    <img src="../assets/worksDetail/healthyExpert/healthyExpertPromo.png" class="img-fluid h-100 w-100 object-fit-fill" />
                                    <template v-slot:overlay>
                                        <div class="d-flex flex-column">
                                            <h3>{{ $t('message.project.healthyExpert.title') }} </h3>
                                            <!-- <p>{{ $t('message.project.healthyExpert.abstract') }} </p> -->
                                        </div>
                                    </template>
                                </CardWithDetail>
                            </div>
                            <div class="col-12 col-md-6">
                                <CardWithDetail class="h-100" toURL='/works/musicBuddy'>
                                    <img src="../assets/worksDetail/musicBuddy/musicBuddy.png" class="img-fluid h-100 w-100 object-fit-fill" />
                                    <template v-slot:overlay>
                                        <div class="d-flex flex-column">
                                            <h3>{{ $t('message.project.musicBuddy.title') }} </h3>
                                            <!-- <p>{{ $t('message.project.musicBuddy.abstract') }} </p> -->
                                        </div>
                                    </template>
                                </CardWithDetail>
                            </div>
                        </div>
                    </div>
                    <div class="d-none d-md-block col-md-12 mt-3">
                        <div class="d-flex justify-content-center py-5 rounded-3" style="background-color: #2c3849;">
                            <h1 class="fw-bold text-white m-0 my-auto" style="font-size: 60px;">Apps + Code</h1>
                        </div>
                    </div>
                </div> 
            </div>
        </div>


        <div class="education wow fadeInUp" data-wow-duration="2s" style="margin-top: 100px;">
            <div id="educations" class="row mt-5 text-center d-flex justify-content-center wow animate__fadeIn g-3">
                <div class="col-10 col-md-6" style="cursor: pointer;"  @click="toURL('https://www.sheffield.ac.uk/undergraduate/courses/2025/computer-science-software-engineering-beng')">
                    <CardWithDetail toURL='' :isShadow="true">
                            <div class="row d-flex justify-content-center g-2 p-5">
                                <div class="col-4 col-md-3 d-flex flex-column justify-content-center"><img src="../assets/logo/tuoslogo.png" class="img-fluid"></div>
                                <div class="col-12 col-md-9">
                                    <h3 class="fs-3 fw-bold">{{ $t('message.about.edu.name') }}</h3>
                                    <p><i class="bi bi-star-fill mx-1"></i>{{ $t('message.about.edu.bachelor_title') }}</p>
                                    <p><i class="bi bi-star-fill mx-1"></i>2020 - 2023</p>
                                    <p><i class="bi bi-star-fill mx-1"></i>{{ $t('message.about.edu.bachelor_degree') }}</p>
                                </div>
                            </div>
                        <template v-slot:overlay>
                            <div class="d-flex flex-column">
                                <h3>{{ $t('message.about.edu.bachelor_courses') }} </h3>
                            </div>
                        </template>
                    </CardWithDetail>
                    
                </div>

                <div class="col-10 col-md-6 " style="cursor: pointer;"  @click="toURL('https://www.sheffield.ac.uk/postgraduate/taught/courses/2025/advanced-computer-science-msc')">
                    <CardWithDetail toURL='' :isShadow="true">

                        <div class="row d-flex justify-content-center g-2 p-5">
                            <div class="col-4 col-md-3 d-flex flex-column justify-content-center"><img src="../assets/logo/UOSLogo.png" class="img-fluid"></div>
                            <div class="col-12 col-md-9">
                                <h3 class="fs-3 fw-bold">{{ $t('message.about.edu.name') }}</h3>
                                <p><i class="bi bi-star-fill mx-1"></i>{{ $t('message.about.edu.master_title') }}</p>
                                <p><i class="bi bi-star-fill mx-1"></i>2023 - 2024</p>
                                <p><i class="bi bi-star-fill mx-1"></i>{{ $t('message.about.edu.master_degree') }}</p>
                            </div>
                        </div>

                        <template v-slot:overlay>
                            <div class="d-flex flex-column">
                                <h3>{{ $t('message.about.edu.master_courses') }} </h3>
                            </div>
                        </template>
                    </CardWithDetail>
                    
                </div>
            </div>
        </div>

<!--        <div class="project wow fadeIn" data-wow-duration="2s">-->
<!--            <div class="title mt-5 ">-->
<!--                <h3 class="px-5 mt-5 my-3">{{ $t('message.about.focus.title') }}</h3>-->
<!--            </div>-->
<!--            <div id="works-contents" class="row mx-4 mt-5">-->
<!--                <div class="col-12 col-md-6 col-xxl-3">-->
<!--                    <WorkContent :img="require('@/assets/logo/web.png')"-->
<!--                                 :title="$t('message.about.focus.web_development.title')">-->
<!--    &lt;!&ndash;                    <template v-slot:content>{{ $t('message.home.focus.web_development.content') }}</template>&ndash;&gt;-->
<!--                    </WorkContent>-->
<!--                </div>-->
<!--                <div class="col-12 col-md-6 col-xxl-3">-->
<!--                    <WorkContent :img="require('@/assets/logo/mobile.png')"-->
<!--                                 :title="$t('message.about.focus.mobile_development.title')">-->
<!--    &lt;!&ndash;                    <template v-slot:content>{{ $t('message.home.focus.mobile_development.content') }}</template>&ndash;&gt;-->
<!--                    </WorkContent>-->
<!--                </div>-->
<!--                <div class="col-12 col-md-6 col-xxl-3">-->
<!--                    <WorkContent :img="require('@/assets/logo/ai.png')"-->
<!--                                 :title="$t('message.about.focus.ai.title')">-->
<!--    &lt;!&ndash;                    <template v-slot:content>{{ $t('message.home.focus.ai.content') }}</template>&ndash;&gt;-->
<!--                    </WorkContent>-->
<!--                </div>-->
<!--                <div class="col-12 col-md-6 col-xxl-3">-->
<!--                    <WorkContent-->
<!--                        :img="require('@/assets/logo/repair.png')"-->
<!--                        :title="$t('message.about.focus.test.title')">-->

<!--    &lt;!&ndash;                    <template v-slot:content>{{ $t('message.home.focus.test.content') }}</template>&ndash;&gt;-->
<!--                    </WorkContent>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <div class="stack wow fadeInUp" data-wow-duration="3s" style="margin-top: 100px;">
            <!-- <div class="title mt-5">
                <h3 class="px-5 mt-5 my-3">{{ $t('message.about.stack.title') }}</h3>
            </div> -->

            <div class="card rounded-5 shadow-lg p-3 ">
                <div id="stack-contents" class="row p-4 g-2">
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/git.png')"
                                    stackName="Git" bgc="#f05032"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/vue.png')"
                                    stackName="VUE.JS" bgc="#35495e"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/bootstrap.png')"
                                    stackName="BOOTSTRAP" bgc="#563d7c"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/html.png')"
                                    stackName="HTML" bgc="#239120"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/css.png')"
                                    stackName="CSS" bgc="#239120"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 ">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/java-script.png')"
                                    stackName="JAVASCRIPT" bgc="#f7df1e"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/android.png')"
                                    stackName="ANDROID" bgc="#3ddc84"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/linux.png')"
                                    stackName="LINUX" bgc="#fcc624"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/python.png')"
                                    stackName="PYTHON" bgc="#3776ab"></SingleStack>
                    </div>


                    <div class="d-none d-md-block col-md-6">
                        <div class="d-flex justify-content-center py-3 rounded-3" style="background-color: #2c3849;">
                            <h1 class="fw-bold text-white m-0 my-auto" style="font-size: 60px;">SKILLS</h1>
                        </div>
                    </div>

                    <!-- <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/ejs.png')"
                                    stackName="EJS" bgc="#239120"></SingleStack>
                    </div> -->
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/node-js.png')"
                                    stackName="NODE.JS" bgc="#43853d"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 ">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/java.png')"
                                    stackName="JAVA" bgc="#ed8b00"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/kotlin.png')"
                                    stackName="KOTLIN" bgc="#0095d5"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/ruby.png')"
                                    stackName="RUBY" bgc="#cc342d"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/mysql.png')"
                                    stackName="MYSQL" bgc="#00000f"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/postgresql.png')"
                                    stackName="POSTGRESQL" bgc="#316192"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/mongoDb.png')"
                                    stackName="MONGODB" bgc="#499D4A"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/nginx.png')"
                                    stackName="NGINX" bgc="#009639"></SingleStack>
                    </div>
                    
                    

                        
                    <!-- <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/haskell.png')"
                                    stackName="HASKELL" bgc="#8F4E8B"></SingleStack>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/opengl.png')"
                                    stackName="OpenGL" bgc="#4a7ca9"></SingleStack>
                    </div> -->
                    <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/swift.png')"
                                    stackName="Swift" bgc="#fc3f1b"></SingleStack>
                    </div>
                    <!-- <div class="col-6 col-sm-4 col-md-3">
                        <SingleStack :imgSrc="require('@/assets/stackLogo/ios.png')"
                                    stackName="iOS" bgc="#000000"></SingleStack>
                    </div> -->
                </div>
            </div>

            
        </div>


        <!-- <div class="cat row d-flex flex-row g-0">
            <div class="col"><img src="@/assets/kitty/240620.jpg" alt="" class="img-fluid h-100 object-fit-cover "></div>
            <div class="col"><img src="@/assets/kitty/240223.jpg" alt="" class="img-fluid h-100 object-fit-cover"></div>
            <div class="col"><img src="@/assets/kitty/240124.jpg" alt="" class="img-fluid h-100 object-fit-cover"></div>
            <div class="col"><img src="@/assets/kitty/231122.jpg" alt="" class="img-fluid h-100 object-fit-cover"></div>
            <div class="col"><img src="@/assets/kitty/210807.jpg" alt="" class="img-fluid h-100 object-fit-cover"></div>
            <div class="col"><img src="@/assets/kitty/210522.jpg" alt="" class="img-fluid h-100 object-fit-cover"></div>
        </div> -->

        <div class="card rounded-5 overflow-hidden shadow-lg wow fadeInUp" data-wow-duration="2s" style="margin-top: 100px;">
            <div id="map" style="height: 500px;"></div>
        </div>



    </div>
</template>

<script
    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBJtnXvWVd_aeGOUN3Ei6k1q3_iOv142TE&libraries=geometry,places"
    async defer>
import {defineComponent} from "vue";
import MyInfoCard from "@/components/MyInfoCard.vue";

export default defineComponent({
    components: {MyInfoCard}
})
</script>

<script>
import WorkContent from '@/components/WorkContent';
import SingleStack from '@/components/SingleStack';
import CardWithDetail from '@/components/CardWithDetail';
const today = require('../utils/today')
import { postVisitorAPI,pushVisitorAPI } from '@/api';
import Typed from 'typed.js'

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import AnimatedButton from "@/components/AnimatedButton.vue";
import MyInfoCard from "@/components/MyInfoCard.vue";

// 修复 marker 图标路径问题
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


export default {
    components: {
        WorkContent,
        SingleStack,
        CardWithDetail,
        AnimatedButton,
        MyInfoCard
    },
    data() {
        return {
            typed: null,
            currentLine: 0,
            language: this.$i18n.locale,
        }
    },
    async created() {
        // 等待Google Maps API加载完成
        // await this.loadGoogleMapsAPI();
        // const body = await this.getLocation();
        // await this.pushNotification(body);
    },
    async mounted() {
        // this.startTyping()

        // Watch for language changes and reset the typing effect
        this.$watch('$i18n.locale', (newLocale, oldLocale) => {
            this.onLanguageChange(newLocale);
        });

        this.mapInit()
    },
    methods: {
        mapInit(){
            const map = L.map('map').setView([39.0, 60.0], 2); // 设置地图的初始位置和缩放级别
  
            // 加载高德地图瓦片
            L.tileLayer('https://webrd04.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}', {
                attribution: '&copy; 高德地图',
                maxZoom: 8,
                minZoom: 2
            }).addTo(map);

            // 自定义圆形带白色背景的标记
            const redIcon = L.divIcon({
                html: `
                    <div class="rounded-circle bg-white w-100 h-100" style="border: 5px solid #FF6666;">
                        <img src="${require('../assets/avatar3.png')}" class="img-fluid w-100 h-100" style="z-index: 1;">
                    </div>
                `,
                className: '', // 不使用默认样式
                iconSize: [50, 50], // 自定义图标大小
                iconAnchor: [25, 50], // 锚点位置
                popupAnchor: [0, -45] // 弹出框位置
            });


            // 定义要添加的标记位置和信息
            const locations = [
                { coords: [23.1291, 113.2644], name: '广州', icon: redIcon, zIndex: 1000 },
                { coords: [25.0375, 121.5636], name: '台北' },
                { coords: [23.6832, 113.0505], name: '清远' },
                { coords: [22.9241, 113.8827], name: '东莞' },
                { coords: [22.5445, 114.0545], name: '深圳' },
                { coords: [22.8170, 108.3669], name: '南宁' },
                { coords: [25.2736, 110.2900], name: '桂林' },
                { coords: [24.7785, 110.4966], name: '阳朔' },
                { coords: [20.0440, 110.1999], name: '海口' },
                { coords: [18.2528, 109.5119], name: '三亚' },
                { coords: [18.8007, 110.3897], name: '万宁' },
                { coords: [19.5433, 110.7977], name: '文昌' },
                { coords: [22.8052, 113.2936], name: '顺德' },
                { coords: [22.3193, 114.1694], name: '香港' },
                { coords: [22.1987, 113.5439], name: '澳门' },
                { coords: [23.1167, 114.4167], name: '惠州' },
                { coords: [31.2304, 121.4737], name: '上海' },
                { coords: [32.0603, 118.7969], name: '南京' },
                { coords: [45.8038, 126.5349], name: '哈尔滨' },
                { coords: [43.8171, 125.3235], name: '长春' },
                { coords: [41.8057, 123.4328], name: '沈阳' },
                { coords: [42.3705, 128.1500], name: '长白山' },
                { coords: [1.3521, 103.8198], name: '新加坡' },
                { coords: [4.2105, 101.9758], name: '马来西亚' },
                { coords: [38.9637, 35.2433], name: '土耳其' },
                { coords: [53.3811, -1.4701], name: '谢菲尔德' },
                { coords: [53.8008, -1.5491], name: '利兹' },
                { coords: [49.2144, -2.1312], name: '泽西岛' },
                { coords: [48.8566, 2.3522], name: '巴黎' },
                { coords: [41.3851, 2.1734], name: '巴塞罗那' },
                { coords: [52.3676, 4.9041], name: '阿姆斯特丹' },
                { coords: [52.4862, -1.8904], name: '伯明翰' },
                { coords: [51.5074, -0.1278], name: '伦敦' },
                { coords: [52.9548, -1.1581], name: '诺丁汉' },
                { coords: [53.4084, -2.9916], name: '利物浦' },
                { coords: [50.8225, -0.1372], name: '布莱顿' },
                { coords: [55.9533, -3.1883], name: '爱丁堡' },
                { coords: [55.8642, -4.2518], name: '格拉斯哥' },
                { coords: [56.4620, -2.9707], name: '邓迪' },
                { coords: [54.9784, -1.6174], name: '纽卡斯特' },
                { coords: [53.4808, -2.2426], name: '曼彻斯特' },
                { coords: [54.7753, -1.5849], name: '杜伦' },
                { coords: [53.2307, -0.5406], name: '林肯' },
                { coords: [34.3416, 108.9398], name: '西安' },
                { coords: [39.9042, 116.4074], name: '北京' }
            ];

            // 循环添加标记
            locations.forEach(location => {
                // 如果有自定义图标则使用自定义图标，否则使用默认图标
                const marker = L.marker(location.coords, { 
                    icon: location.icon || new L.Icon.Default(),
                    zIndexOffset: location.zIndex || 0 // 设置 zIndexOffset 值，红色标记较大 
                }).addTo(map);
                marker.bindPopup(location.name); // 添加弹出框
            });
            

            // 创建自定义控件
            L.Control.CustomDiv = L.Control.extend({
                onAdd: function (map) {
                    // 创建一个 div 元素
                    const customDiv = L.DomUtil.create('div', 'base');

                    // 直接使用 HTML 模板和 Bootstrap 类
                    // this.$t('message.about.location')
                    customDiv.innerHTML = `
                        <div class="d-none d-md-block rounded-5 bg-white px-5 py-3">
                            <p class="fw-bold fs-4 text-center">广州, 中国</p> 
                        </div>
                    `;

                    return customDiv;
                },

                // 设置控件的位置
                getPosition: function () {
                    return 'bottomleft'; // 设定为左下角
                }
            });

            // 添加自定义 div 控件
            const customDiv = new L.Control.CustomDiv();
            map.addControl(customDiv);


        },

        onLanguageChange(newLocale) {
            // Handle language change

            // Reset the typing effect
            this.currentLine = 0; // Reset current line to start from the beginning
            this.typed.destroy(); // Destroy the current Typed instance to clear the text
            this.typed.el.innerHTML = ''; // Clear any existing text

            this.startTyping(); // Restart typing effect with updated language
        },
        getLines() {
            return [
                '>>> import tenenzzzz ^300',
                this.$t('message.about.hello'),
                '>>> tenenzzzz.job ^300',
                this.$t('message.about.job'),
                '>>> tenenzzzz.interests ^300',
                this.$t('message.about.interests'),
                '>>> tenenzzzz.education ^300',
                this.$t('message.about.education')
            ];
        },
        startTyping() {
            this.typed = new Typed('#typed', {
                strings: [''],
                typeSpeed: 100,
                showCursor: false,
                cursorChar: '|',
                onComplete: () => {
                    this.typeNextLine()
                }
            })
        },
        typeNextLine() {
            const lines = this.getLines();  // 动态获取当前的翻译内容

            if (this.currentLine < lines.length) {
                const lineText = lines[this.currentLine];

                // 创建新的行元素
                const lineElement = document.createElement('span');
                this.typed.el.appendChild(lineElement);

                // 创建新的光标元素
                const cursorElement = document.createElement('span');
                cursorElement.className = 'typed-cursor typed-cursor--blink'; // 添加光标样式类
                cursorElement.textContent = '|'; // 光标字符
                this.typed.el.appendChild(cursorElement);

                if (lineText.startsWith('>>>')) {
                    lineElement.className = 'fw-bold';

                    // 分割 ">>>" 和剩余的内容
                    const commandPrefix = '>>> ';
                    const remainingText = lineText.substring(commandPrefix.length);

                    // 立即显示 ">>>"
                    const prefixElement = document.createElement('span');
                    prefixElement.textContent = commandPrefix;
                    lineElement.appendChild(prefixElement);

                    setTimeout(() => {
                        // 使用 Typed.js 动画效果显示剩余部分
                        const typingElement = document.createElement('span');
                        lineElement.appendChild(typingElement);

                        new Typed(typingElement, {
                            strings: [remainingText],
                            typeSpeed: 50,
                            showCursor: false, // 隐藏 Typed.js 默认光标
                            onComplete: () => {
                                cursorElement.style.display = 'none'; // 隐藏手动创建的光标
                                this.currentLine++;
                                this.typeNextLine();
                            }
                        });
                    }, 300);  // 延迟 300 毫秒

                } else {
                    cursorElement.style.display = 'none';

                    // 使用 i18n 的翻译函数显示回答内容
                    lineElement.textContent = lineText;  // 确保内容是通过 i18n 动态更新的

                    // 设置一个短暂的延迟后显示下一个，模拟思考或延迟效果
                    setTimeout(() => {
                        this.currentLine++;
                        this.typeNextLine();
                    }, 0);  // 无延迟
                }

                // 插入换行符
                const brElement = document.createElement('br');
                this.typed.el.appendChild(brElement);
            }
        },

        downloadResume() {
            const fileUrl = '/Zhicong_CV_zh.pdf'; // 替换为你的静态 PDF 文件路径
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = '蒋志聪_中文简历.pdf'; // 设置下载文件的名称
            link.click();
        },
        async loadGoogleMapsAPI() {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAEWKP6g2pWXqE4kKDnM_sUsw6qmI3_tCc&libraries=geometry,places`;
                script.async = true;
                script.defer = true;
                script.onload = resolve;
                script.onerror = reject;
                document.head.appendChild(script);
            });
        },

        async getLocation() {
            let latitude = 0;
            let longitude = 0;
            let location = "未知地址";

            // 创建一个新的地理编码器实例
            const geocoder = new google.maps.Geocoder();

            try {
                // 检查浏览器是否支持Geolocation API
                if ("geolocation" in navigator) {
                    // 支持Geolocation
                    const position = await new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(resolve, reject);
                    });

                    // 获取经度和纬度
                    latitude = position.coords.latitude;
                    longitude = position.coords.longitude;

                    // 创建一个 LatLng 对象
                    const latLng = new google.maps.LatLng(latitude, longitude);

                    // 执行逆地理编码
                    const results = await new Promise((resolve, reject) => {
                        geocoder.geocode({ location: latLng }, function (results, status) {
                            if (status === google.maps.GeocoderStatus.OK) {
                                resolve(results);
                            } else {
                                reject("逆地理编码失败：" + status);
                            }
                        });
                    });

                    if (results[0]) {
                        // 获取第一个结果的地理位置信息
                        location = results[0].formatted_address;
                        console.log("地理位置信息：", location);
                        return `${latitude},${longitude}  ${location}`;
                    } else {
                        throw new Error("未找到结果");
                    }
                } else {
                    // 不支持Geolocation
                    throw new Error("浏览器不支持Geolocation API");
                }
            } catch (error) {
                console.error(error);
                return "无法获取位置信息";
            }
        },


        async pushNotification(body){
            const params = new URLSearchParams();
            params.append('title', "新的访问者");
            params.append('body', body);
            params.append('icon', "https://static.tvtropes.org/pmwiki/pub/images/beluga.jpg");
            params.append('url', "http://terenzzzz.cn");
            params.append('sound', "calypso");
            const res = await pushVisitorAPI(params)
            console.log(res);
        },
        toURL(url){
            window.open(url, '_blank');
        }
    }
}
</script>

<style lang="css" scoped>
#map {
  width: 100%;
  height: 500px; /* 确保高度不为 0 */
}



@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=ZCOOL+KuaiLe&display=swap');
.ibm-plex-mono-regular {
    font-family: "IBM Plex Mono", monospace;
    font-weight: 400;
    font-style: normal;
}



#typed {
    white-space: pre-wrap;
    word-break: break-word;
}
.title{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title h3{
    border-bottom: 3px solid #FF6666;
}

.img-container {
    position: relative;
    padding-bottom: 100%; /* 创建一个正方形容器 */
}

.img-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

</style>