<template>
    <div id="SingleWork" class="h-100">
        <router-link :to="toURL" class="text-decoration-none ">
            <div class="card overflow-hidden h-100">
                <img :src="img" class="img-fluid" style="image-rendering: smooth;"/>

                <div class="card-body">
                    <h5 class="card-title fw-bold fs-4">{{ title }}</h5>
                    <span class="card-text content fs-6 text-muted">{{ abstract }}</span>
                </div>

                <div class="card-footer d-flex flex-wrap" v-if="tags.length > 0">
                    <!-- Ensure footer is fixed at the bottom -->
                    <div v-for="word in tags" :key="word.id" class="d-flex mb-2">
                        <TagButton :tag="word" class="mx-1"></TagButton>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import TagButton from "@/components/TagButton.vue";

export default {
    components: {
        TagButton
    },
    props: {
        toURL: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        abstract: {
            type: String,
            required: true
        },
        img: {
            type: String,
            required: true
        },
        tags: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        }
    },
};
</script>

<style lang="css" scoped>
#SingleWork {
    margin: 20px;
    height: 100%;
}

.card {
    border-radius: 30px;
    height: 100%;
    transition: 0.2s;
}

.card:hover  {
    border: 2px solid #6e6d7a;
    transform: scale(1.1);
    z-index: 10000;
}

.content {
    background: linear-gradient(to right, #bebcbc, #bebcbc) no-repeat left bottom;
    background-size: 0px 2px;
    transition: 0.5s;
}

.card:hover .content {
    background-size: 100% 2px;
}

.card img {
    border-radius: 30px 30px 0px 0px;
    height: 200px;
    max-height: 200px;
    object-fit: cover;
}
</style>
