<template>
    <div class="mt-5 container">
       <WorkHeader :title="$t('message.project.theBigWalk.title')" date="2023/07/15"/>
        <div class="row d-flex justify-content-center">
            <div class="col-12">

                <!--标题卡片-->
                <div class="card bg-light border-0 rounded-4 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <div class="d-flex align-items-center">
                                <div class="d-none d-md-block me-5 border border-1 rounded-5 shadow-sm ">
                                    <img src="@/assets/worksDetail/theBigWalk/icon.png" class="img-fluid rounded-3" style="width: 100px; height: 100px;"/>
                                </div>

                                <div>
                                    <h1 class="fw-bold">{{ $t('message.project.theBigWalk.app_name') }}</h1>
                                    <p class="text-muted">{{ $t('message.project.theBigWalk.title') }}</p>
                                </div>
                            </div>
                            <div class="d-none d-md-flex flex-wrap mt-3">
                                <div v-for="(word,index) in ['Ruby','Ruby on Rails','BootStrap', 'POSTGRESQL']" :key="index" class="d-flex mb-2">
                                    <TagButton :tag="word" class="mx-1"></TagButton>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <img src="@/assets/worksDetail/theBigWalk/theBigWalk_matting.png" class="img-fluid">
                        </div>
                    </div>
                </div>

                <!--项目背景卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class="d-flex justify-content-center"><h3 class="text-center red-bottom ">{{$t('message.project.theBigWalk.background.header')}}</h3></div>

                    <p>{{$t('message.project.theBigWalk.background.content')}}</p>
                </div>

                <!--项目目标卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center ">
                            <h3 class="text-center red-bottom">{{$t('message.project.musicBuddy.aim.header')}}</h3>

                            <p class="fw-bold mt-3 ps-3">{{ $t('message.project.theBigWalk.aim.content.paragraph1') }}</p>
                            <ul>
                                <li v-for="(item, index) in $t('message.project.theBigWalk.aim.content.listItems')" :key="index">
                                    {{ item }}
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-6 mt-2 mt-lg-0 d-flex justify-content-center">
                            <table>
                                <tr>
                                    <th>#</th>
                                    <th>{{$t('message.project.stack.type')}}</th>
                                    <th>{{$t('message.project.stack.stack')}}</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>{{$t('message.project.stack.front_end')}}</td>
                                    <td>HAML</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>{{$t('message.project.stack.back_end')}}</td>
                                    <td>Ruby; Ruby On Rails</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>{{$t('message.project.stack.database')}}</td>
                                    <td>PostgreSQL</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>{{$t('message.project.stack.editor')}}</td>
                                    <td>VS Code;</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>{{$t('message.project.stack.version_control')}}</td>
                                    <td>GitHub</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>


                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <h3 class="red-bottom">{{$t('message.project.theBigWalk.features.header')}}</h3>

                    <div class="row mt-5">
                        <div class="col-12 col-lg-4">
                            <h5 class="ps-3">{{$t('message.project.theBigWalk.features.host.role')}}</h5>
                            <ul>
                                <li v-for="(item, index) in $t('message.project.theBigWalk.features.host.ability')" :key="index">
                                    {{ item }}
                                </li>
                            </ul>
                        </div>

                        <div class="col-12 col-lg-4">
                            <h5 class="ps-3">{{$t('message.project.theBigWalk.features.marshall.role')}}</h5>
                            <ul>
                                <li v-for="(item, index) in $t('message.project.theBigWalk.features.marshall.ability')" :key="index">
                                    {{ item }}
                                </li>
                            </ul>
                        </div>

                        <div class="col-12 col-lg-4">
                            <h5 class="ps-3">{{$t('message.project.theBigWalk.features.walker.role')}}</h5>
                            <ul>
                                <li v-for="(item, index) in $t('message.project.theBigWalk.features.walker.ability')" :key="index">
                                    {{ item }}
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>




                <div class=" card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <h3 class="red-bottom">{{$t('message.project.theBigWalk.feedback.header')}}</h3>
                    <p >{{$t('message.project.theBigWalk.feedback.content')}}</p>


                    <p class="fw-bold mt-3">{{$t('message.project.theBigWalk.price.name')}}</p>
                    <p class="text-muted small">* {{$t('message.project.theBigWalk.price.content')}}</p>
                </div>
                
               
                
            </div>
        </div>
    </div>
       
</template>

<script>
import WorkHeader from '@/components/WorkHeader';
import TagButton from "@/components/TagButton.vue";


export default {
    components: {
        TagButton,
        WorkHeader,
    },

};
</script>

<style lang="css" scoped>

table {
    border-collapse: collapse;
    width: 100%;
}
th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}
th {
    background-color: #f2f2f2;
}

li{
    list-style-type: circle;
}

.subTitle{
    font-weight: bold;
}
</style>