// src/locales/en.js
export default {
    message: {
        navbar:{
            about: 'About',
            project: 'Project',
            blog: "Blog",
            kitty: "Kitty",
            cocktail: "CockTail",
            contact: "Contact"
        },
        about:{
            hello: `Hi，I'm Terence!`,

            name:{
                header: 'Name：',
                content: '[ Zhicong, Terence ]'
            } ,
            job: {
                header: 'Job： ',
                content: '[Web Developer🧑‍💻, Software Developer🧑‍💻]'
            },
            interests: {
                header: 'Interests： ',
                content: '[\'Kitty🐈\', \'Music🎵\', \'Cocktail🍹\']'
            },
            education: {
                header: 'Degree: ',
                content: '[BEng Software Engineering, MSc Advance Computer Science]'
            },
            location: {
                header: 'Location：',
                content: '[ Guangzhou, China ]'
            },

            apps:{
                apps: 'APPS',
                coursework: 'made for coursework',
                dissertation: 'made for dissertation'
            },
            edu:{
                title: 'EDUCATION',
                name: 'The University of Sheffield',
                bachelor_title: 'BEng Software Engineering',
                bachelor_degree: 'Class One Honours',
                master_title: 'Msc Advance Computer Science',
                master_degree: 'Distinction'
            },
            focus:{
                title: 'FOCUS',
                web_development:{
                    title:'Web Development',
                    content: 'Familiarize with a variety of programming languages, front-end and back-end technologies, database systems, network protocols, and security measures to create high-performance, secure and reliable websites'
                },
                mobile_development:{
                    title:'Mobile App Development',
                    content: 'Android and iOS mobile software development is one of my areas of interest. The ability to develop cross-platform applications and native applications to meet the needs of different platforms.'
                },
                ai:{
                    title:'AI & Machine Learning',
                    content: 'It focuses on developing intelligent systems and algorithms that use data and algorithms to build and train models for applications such as speech recognition, image processing, natural language processing, and more.'
                },
                test:{
                    title:'Software Testing',
                    content: 'Write test plans and test cases, perform functional tests, performance tests and security tests to verify the correctness and performance of the software.'
                },
            },
            stack:{
                title: 'STACKS'
            }
        },
        project: {
            header: "Project",
            view: "View",
            stack: {
                type: "Type",
                stack: "Stack",
                front_end: "Front-end",
                back_end: "Back-end",
                api_testing: "API Testing Platform",
                database: "Database",
                editor: "Source Code Editor",
                server: "Server",
                architecture: "Architecture",
                version_control: "Version Control",
                apis: "API Library",
            },
            meowID: {
                title: 'MeowID Cat Breed Inference Tool',
                abstract: 'MeowID is a deep learning-based cat breed recognition application. Users can upload images of their cats, and the app will automatically recognize and classify the cat\'s breed, providing the probability percentage for each breed. Through this app, users can easily understand the breed of their cat and obtain related breed information.',
                app_name: 'MeowID',
                aim: {
                    header: "Project Goals",
                    content: "Build a cat breed classification model based on PyTorch. This model uses a training dataset containing images of cats and can accurately classify different cat breeds. During the model development, the ResNet-50 pre-trained model was used and fine-tuned to achieve the best classification performance."
                },
                feature: {
                    coreTech: {
                        header: "Core Technologies",
                        content: [
                            "Deep Learning Framework: This project uses PyTorch to build a cat breed classification model. To improve classification accuracy, a ResNet-50 pre-trained model was fine-tuned to meet the specific needs of cat breed classification.",
                            "Model Packaging & API: The model is packaged as a web service using Flask. Users can upload cat images via HTTP requests, and the API returns the predicted breed and its corresponding probability."
                        ]
                    },
                    trainResult: {
                        header: "Training and Test Results",
                        trainLoss: "Training Loss",
                        trainAccuracy: "Training Accuracy",
                        testLoss: "Test Loss",
                        testAccuracy: "Test Accuracy",
                    },
                    resultEachClass: {
                        header: "Model Accuracy for Each Breed",
                        EnglishClassName: "English Breed Name",
                        ChineseClassName: "Chinese Breed Name",
                        accuracy: "Accuracy"
                    },
                    source: {
                        header: "Data Sources",
                        datasetSource: {
                            header: "Dataset Source",
                            content: "Aml Dataset"
                        },
                        frontEndRepo: {
                            header: "Frontend Vue Repository",
                            content: "GitHub Repository"
                        },
                        backEndRepo: {
                            header: "Backend Repository",
                            content: "GitHub Repository"
                        }
                    }
                }
            },

            joFind: {
                title: 'JoFind Recruitment Platform',
                abstract: 'JoFind is a comprehensive recruitment platform designed to provide efficient matching and communication for job seekers and recruiting companies. Job seekers can browse job postings, submit resumes, and chat with HR online; companies can post jobs, manage applications, review resumes, and interact with job seekers.',
                app_name: 'JoFind',
                aim: {
                    header: "Project Objective",
                    content: "The JoFind recruitment platform aims to provide job seekers with a convenient platform for job searching and resume submission, while offering recruiting companies an efficient recruitment management system. The platform helps job seekers filter job postings precisely using various criteria such as salary range, work experience, location, and job type. Job seekers can also communicate with HR in real-time, check their application status, and more. For recruiting companies, the platform offers job posting, application management, resume review, and other features to optimize the recruitment process.",
                },
                feature: {
                    header: "System Features",
                },
                interface: {
                    header: "User Interface",
                    seeker: "Job Seeker Interface",
                    company: "Company Interface",
                    login: {
                        header: "Identity Authentication",
                        content: "JoFind distinguishes between two user roles: job seekers and companies. It provides dedicated functional interfaces and services tailored to different user needs, ensuring ease of operation, efficiency, and a user-friendly experience.",
                    },
                    jobMarket: {
                        header: "Job Browsing",
                        content: "Job seekers can browse all job postings on the platform and filter positions that meet their needs to obtain detailed job descriptions and company information. They can directly submit their resumes to target positions and quickly participate in the recruitment process.",
                    },
                    chat: {
                        header: "Real-Time Online Communication",
                        content: "Both job seekers and HR can communicate in real-time through the platform. Job seekers can ask questions and learn more about job details or interview arrangements; HR can quickly respond to job seekers' inquiries, provide recruitment information, or arrange interviews.",
                    },
                    resume: {
                        header: "Personal Online Resume Maintenance",
                        content: "Provides online resume editing and storage functionality, supporting the maintenance of personal basic information, including expected positions, internship experiences, work experiences, project experiences, educational background, language skills, and more. Resume content can be quickly updated to ensure completeness and accuracy, thereby improving application efficiency and success rates.",
                    },
                    applicationRecord: {
                        header: "Application Status Tracking",
                        content: "Job seekers can track the status of their submitted resumes in real-time.",
                    },
                    jobManage: {
                        header: "Job Management for Companies",
                        content: "Companies can quickly post and manage recruitment needs on the platform, including job responsibilities, requirements, salary ranges, and other details.",
                    },
                    companyManage: {
                        header: "Company Information Management",
                        content: "Enterprise users can complete their company profiles, including company introductions, industry fields, contact information, and more, to provide references for job seekers.",
                    },
                    applicationManage: {
                        header: "Job Seeker Management",
                        content: "Companies can review all job applications for their positions, including resumes and personal information submitted by job seekers. They can manage all applicants in one place, compare and filter candidates, and select the most suitable ones. The platform also supports updating applicants' statuses, such as 'Resume Viewed,' 'Interview Invitation,' or 'Not Suitable,' to facilitate further communication.",
                    },
                },
            },

            musicBuddy: {
                title: "Lyric-Based Music Exploration and Recommendation System",
                    abstract: "Utilize lyrics, lyric-derived features, song metadata, user preferences, and listening history to build a music exploration and intelligent recommendation system. Provide users with personalized music content and more accurate and comprehensive music information.",
                    app_name: "MusicBuddy",
                    aim: {
                    header: "Project Aim",
                        content: "This project reviews current music recommendation systems on the market and various recommendation algorithms to identify key needs and challenges. The goal of the project is to build a music exploration and intelligent recommendation system using lyrics, lyric-derived features, song metadata, user preferences, and listening history. By providing reliable algorithms and a user-friendly visual interface, the system aims to offer personalized music content and more accurate, comprehensive music information. This helps users gain a deeper understanding of music content, further meet their needs, and allows them to easily browse, search, and enjoy music.",
                        stack: {
                        type: "Type",
                            stack: "Technology Stack",
                            front_end: "Frontend",
                            back_end: "Backend",
                            api_testing: "API Testing Platform",
                            database: "Database",
                            editor: "Source Code Editor",
                            version_control: "Version Control"
                    }
                },
                feature: {
                    header: "System Features"
                },
                interface: {
                    header: "User Interface",
                        recommendation: {
                        header: "Music Recommendation",
                            content: "Based on content filtering with lyrics as input, TF-IDF is used to capture keywords in lyrics, Word2Vec captures semantics, and LDA captures latent topics for music recommendation. Collaborative filtering recommends music liked by similar users based on user rating data."
                    },
                    random_recommendation: {
                        header: "Random Recommendation",
                            content: "The system provides random music for user exploration, allowing simple swipes to switch music, providing key music metadata, music playback, and rating features."
                    },
                    integration: {
                        header: "Spotify Integration",
                            content: "Supports linking Spotify accounts to retrieve user listening history. Also supports playback functionality provided by Spotify music sources."
                    },
                    metadata: {
                        header: "Metadata Visualization",
                            content: "The application provides visualization of various types of metadata, including artist name, album, background, popularity, tags, release date, song duration, etc. Due to copyright reasons, lyrics cannot be displayed on the web page, but the system provides thematic distribution and popular keywords of lyrics."
                    },
                    search: {
                        header: "Keyword Search",
                            content: "Supports keyword search in the local database and Spotify resources, with fuzzy search support, and provides results filtering across three dimensions: music, artists, and lyrics."
                    },
                    tag: {
                        header: "Tag Filtering",
                            content: "Supports filtering searches using tags provided by the system database, with over 30,000+ tags, allowing result filtering in two dimensions: music and artists."
                    },
                    report: {
                        header: "Listening Habit Reports",
                            content: "By analyzing user listening data, generates reports including listening history from Spotify, rating records, and distribution of music listening dates and times, years of music users typically listen to, and tags, keywords, and topics that best represent the user."
                    }
                },
                feedback: {
                    header: "User Feedback",
                        last_update: "Last Update",
                        exploration: {
                        header: "Average Scores and Comments on Music Exploration",
                            comments: [
                            "Search efficiency is low",
                            "More types of reports needed",
                            "Audio sources are not diverse enough",
                            "Metadata and audio source information are not comprehensive",
                            "The music library capacity is insufficient",
                            "More filtering and sorting options needed in search and tagging features"
                        ]
                    },
                    recommendation: {
                        header: "Average Scores and Comments on Music Recommendation",
                            comments: [
                            "Recommendations are not efficient and accurate",
                            "Lack of diversity in input data",
                            "Lack of personalized recommendations (e.g., based on mood, scenario, or type)",
                            "Lack of feedback mechanism for improving future recommendations",
                            "Repetitive recommendation content"
                        ]
                    },
                    system: {
                        header: "Overall System Average Scores and Comments",
                            comments: [
                            "Lack of diversity in third-party connections",
                            "User experience is not smooth",
                            "Needs better user interaction (e.g., track comments, guidance)"
                        ]
                    },
                    part_of_system: {
                        header: "Average Scores for the Five Main Parts of the System"
                    }
                }
            },
            netify: {
                title: "iOS Music Player (Not Finished)",
                abstract: "An iOS music player application developed based on the NetEase Cloud Music API backend project. It aims to provide a simple and elegant front-end interface, simplifying the complex features and views of the native NetEase Cloud Music.",
                app_name: "Netify",
                aim: {
                    header: "Project Objectives",
                    content: "An iOS music player application developed based on the NetEase Cloud Music API backend project. It aims to provide a simple and elegant front-end interface, simplifying the complex features and views of the native NetEase Cloud Music, allowing users to easily browse, search, and enjoy music."
                },
                feature: {
                    header: "System Features"
                },
                interface: {
                    header: "User Interface",
                    login: {
                        header: "Connect NetEase Cloud Music Account",
                        content: "Securely log in to NetEase Cloud Music account via SMS verification code."
                    },
                    recommendation: {
                        header: "Music Recommendation",
                        content: "The system provides random music for users to explore, allows simple swiping to switch music, and offers key music metadata, music playback, and rating features."
                    },
                    lyric: {
                        header: "Lyric Display",
                        content: "Supports linking Spotify account to access user's listening history. Also supports playback functionality provided by Spotify music source."
                    },
                    metadata: {
                        header: "Metadata Visualization",
                        content: "The application provides visualization of various metadata, including basic data such as song artist's name, album, background, popularity, tags, release date, and song duration."
                    },
                    search: {
                        header: "Keyword Search",
                        content: "Supports searching for music and artists by keywords."
                    },
                    report: {
                        header: "Listening Report",
                        content: "Generates reports based on user's listening data."
                    }
                }
            },
            eWaste: {
                title: 'Electronic Device Recycling System',
                abstract: 'This project aims to provide a central hub that can identify devices based on their age and demand and offers owners the option to pay for data retrieval fees.',
                app_name: 'ePanda',
                aim: {
                    header: "Project Goal",
                    content: 'The project aims to provide a central hub that can identify devices based on their age and demand, offering owners the option to pay for data retrieval fees. If a device is likely to be resold, such as a new smartphone, the hub should determine third parties that the owner may sell their device to, with third parties paying a referral fee for successful resale. Sellers can still choose to retrieve their data and clear their device (for a fee).',
                },
                requirement: {
                    header: 'Requirements Analysis'
                },
                interface: {
                    header: "User Interface",
                    login: {
                        header: "Multi-platform Account",
                        content: "Supports account login and registration via email/password, Google, and Facebook."
                    },
                    market: {
                        header: "Device Marketplace & Personal Device Management",
                        content: "The system provides a marketplace for users to browse and compare prices, easily manage their listed devices, and view real-time status."
                    },
                    addItem: {
                        header: "Device Listing",
                        content: "The database stores information on over 11,000 electronic device models from 116 brands. Users can easily link their devices, upload device images, and input device conditions to receive third-party quotes."
                    },
                    itemDetail: {
                        header: "Device Details",
                        content: "The system provides detailed device parameters and third-party acquisition reference prices. Users can accept quotes and generate QR codes containing device information to transact with third parties or choose the recycling service provided by the platform, including data backup services (paid)."
                    },
                    payment: {
                        header: "Payment",
                        content: "Data backup and recovery services require payment. The system supports payment services through Stripe and PayPal."
                    },
                    dataRetrieve: {
                        header: "Data Backup & Recovery",
                        content: "Data is uploaded by staff, and users can download backup data from the platform or extend the data retention period."
                    },
                    admin: {
                        header: "Admin Dashboard",
                        content: "The system supports three roles: user, staff, and admin. The latter two can log in to the admin dashboard to manage devices, user listings, user management, view reports, and more."
                    },
                },
            },

            cuda:{
                title: 'Optimization by OpenMp and Cuda',
                abstract: 'The single-threaded implementation of the three algorithms is optimized using OpenMp and Cuda'
            },
            nGrams:{
                title: 'Traditional n-grams Language Model',
                abstract: 'Start by building the hash table structure, build a basic n-grams language model and visualize it through the Swing graphical interface'
            },
            openGL:{
                title: 'Render scenes with modern OpenGL',
                abstract: 'use modern OpenGL to render scenes (lighting, texturing, animation, etc.) and make interactive software that uses graphics apis.'
            },
            healthyExpert: {
                title: "Health Monitoring Android App",
                abstract: "Design and develop a collaborative mobile sensing system that connects mobile or wearable devices to support pervasive health applications.",
                app_name: "HealthyExpert",
                aim: {
                    header: "Project Aim",
                    content: "The main goal of this project is to design and develop a collaborative mobile sensing system that uses various sensors on mobile devices to collect, analyze, store, and use personal health data to monitor vital signs, providing a platform for real-time monitoring and tracking of various health data. This system aims to provide more convenient, efficient, and high-quality health services to the general public to support universal health. Functional modules include step monitoring, exercise modes, calorie tracking, sleep monitoring, heart rate detection, water intake reminders, medication reminders, body metrics monitoring, goal setting, period prediction, health consultation, history records, health advice, and more.",
                    stack: {
                        type: "Type",
                        stack: "Technology Stack",
                        front_end: "Frontend",
                        back_end: "Backend",
                        api_testing: "API Testing Platform",
                        database: "Database",
                        server: "Server",
                        architecture: "Architecture",
                        editor: "Source Code Editor",
                        version_control: "Version Control",
                        apis: "API Library"
                    }
                },
                dataflow: {
                    header: "System Flowchart"
                },
                interface: {
                    authentication: {
                        header: "Login & Registration",
                        content: "Users register and log in using their email and password, and the password is hashed before being stored in the database."
                    },
                    main: {
                        header: "Home",
                        content: "The home page of the app displays different health data modules, supports viewing past health data, reading health articles, and monitors basic body metrics on the user's profile."
                    },
                    calorie: {
                        header: "Calorie Tracking",
                        content: "Supports users to add, modify, and delete calorie intake and expenditure records. It also allows setting goals to help users understand their daily energy intake and consumption."
                    },
                    step: {
                        header: "Step Counting",
                        content: "Continuously tracks steps and time in the background, calculates distance, calories burned, and behavior patterns. Users can see if their daily exercise goals are met and their activity levels."
                    },
                    exercise: {
                        header: "Exercise",
                        content: "Supports users in tracking data from different activities like walking, running, cycling, etc. Different algorithms process the data and GPS is used to record the exercise route. It also supports users in checking their daily exercise goals."
                    },
                    water: {
                        header: "Water Intake",
                        content: "Collects user-provided water intake records and sends push notifications to remind users to drink water. Compared to preset goals, it helps users better manage daily water intake and stay hydrated."
                    },
                    medication: {
                        header: "Medication Reminder",
                        content: "Allows users to manually add medications and set reminders for medication intake, with notifications sent based on the set time. This feature is more user-friendly and easier to manage and track for those who need long-term medication."
                    },
                    sleep: {
                        header: "Sleep Monitoring",
                        content: "Monitors sleep duration and environment, generates sleep reports to inform users about how to improve their sleep quality, and helps achieve sleep goals to improve overall health."
                    },
                    heartRate: {
                        header: "Heart Rate Monitoring",
                        content: "Allows users to monitor and record their current heart rate, which helps them understand their health level, heart health, and emotional state, using this information to make changes, set priorities, and move towards a healthier lifestyle."
                    },
                    period: {
                        header: "Period Tracking",
                        content: "Tracking your menstrual cycle and monthly changes can help with family planning and pregnancy prevention."
                    }
                },
                result: {
                    header: "Summary",
                    result: "In the actual development process, due to time constraints, not all pre-analyzed functional requirements could be implemented, but all necessary functions and some worthwhile optional functions have been realized. In terms of testing, performance scores were good, CPU usage was low, and memory usage and energy consumption were also low. Overall, users are quite satisfied with the application, although there are still some bugs. Users also provided valuable suggestions for improvement. This software can meet users' basic needs and help them monitor and understand their health status, enabling them to take appropriate actions. However, there is still much room for improvement to better meet each user's needs and provide a better user experience.",
                    significant: "The popularization of healthcare plans is one of the solutions to a series of crises and challenges currently facing the healthcare system. It can be defined as providing healthcare to anyone, anywhere, anytime by eliminating location, time, and other restrictions while increasing coverage and quality. In contrast, mobile health uses mobile phones or other wireless devices in combination with software to support healthcare, which can help improve disease prevention and self-management, enhance the quality and efficiency of healthcare, reduce healthcare costs, and increase accessibility."
                }
            },
            birdWatching:{
                title: 'Bird Watching Forum PWA',
                abstract: 'A "Bird Watching" progressive web application for users to record and observe birds, and assist with identification.',
                app_name: 'BirdWatching',
                aim:{
                    header: "Project Goal",
                    content: "The goal of this project is to design and develop a 'Bird Watching' progressive web application that allows users to record and observe birds and assist with identification. With this website, users can add new sightings, view sightings, comment on identifications, and connect to knowledge graphs to provide official information."
                },
                interface:{
                    header: "User Interface",
                    create:{
                        header: "Create Record",
                        content: "The system supports recording the date and time of bird watching, location (i.e., geographical position), a (brief) description of the sighting event, identification (which may be unknown or uncertain), and photos."
                    },
                    chat:{
                        header: "Real-time Chat",
                        content: "The system allows users to engage in real-time chat and link to each sighting event."
                    },
                    location:{
                        header: "Google Maps Integration",
                        content: "The system supports interactive maps provided by Google to help clearly record the location of sightings."
                    },
                    offline:{
                        header: "Offline Availability",
                        content: "The system supports both online and offline interactions, with information automatically synchronized when the system goes back online after being offline."
                    },
                    DBPedia:{
                        header: "DBPedia Knowledge Base Support",
                        content: "The system automatically links identified sightings to the DBPedia knowledge base to obtain official detailed information."
                    }
                },

            },
            tourVisitView:{
                title: 'Visitor visit system',
                abstract: 'Design, build and evaluate a city-based personal photo management application that enables users to receive, access and edit relevant information.'
            },
            ros:{
                title: 'ROS robot',
                abstract: 'Use ROS to develop a range of robot behaviors on TurtleBot3 waffles to fulfill multiple tasks such as autonomous obstacle avoidance, maze navigation, autonomous detection search, and more.'
            },
            theBigWalk: {
                title: "Marathon Tracking System",
                abstract: "Design a system for marathon events to effectively track participants, enhance their safety and well-being, and ensure the smooth running of the event.",
                app_name: "The Big Walk",
                background: {
                    header: "Project Background",
                    content: "The Big Walk is a fundraising challenge organized in collaboration with the Sheffield University Alumni Association, held every June. By 2022, up to 400 employees, alumni (former students), and members of the public will travel by subway to the hillside area, covering 30 or 50 kilometers of trails to raise funds for the university's genetic disease research. To ensure their safety, each participant needs to be tracked at various stages of the route. The current system involves assigning a number to each participant on a wristband. At each checkpoint, a marshal marks their number as they pass. If a participant does not pass through, the chief marshal receives an alert and will contact the participant who may be lost or injured."
                },
                aim: {
                    header: "Project Objectives",
                    content: {
                        paragraph1: "The current system is time-consuming, prone to human error, and has communication difficulties among marshals. For future Big Walk events, the organizers aim to:",
                        listItems: [
                            "Implement an effective method to track participants, flagging any potential injuries or ‘lost’ walkers/runners to enhance safety and well-being.",
                            "Develop a system to track who has started the event, who has withdrawn, who might be lost, and who has completed the event.",
                            "Provide route guidance to participants, indicating whether they are ahead, behind, or on target speed, and their finish time.",
                            "Create a data center to store each participant's progress data, updated in real-time, accessible to all marshals and event organizers."
                        ]
                    }
                },
                features: {
                    header: "Features Summary",
                    brief: "Based on discussions with the client, the system is divided into three different roles (Participants, Marshals, Organizers), each with distinct business logic and requirements.",
                    host: {
                        role: "Organizer",
                        ability: [
                            "Organizers can create/modify/terminate events.",
                            "Organizers can grant permissions to other users.",
                            "Organizers can view all accounts and information.",
                            "Organizers can receive and process help requests from participants.",
                            "..."
                        ]
                    },
                    marshall: {
                        role: "Marshal",
                        ability: [
                            "Marshals can select their checkpoint.",
                            "Marshals can switch their checkpoint.",
                            "Marshals can view participants approaching their checkpoint.",
                            "Marshals can manually check-in participants.",
                            "Marshals can terminate/pause/resume work status.",
                            "..."
                        ]
                    },
                    walker: {
                        role: "Participant",
                        ability: [
                            "Participants can register and log in to register for the event.",
                            "Participants can choose the event and route they want to participate in.",
                            "Participants can click the start button to begin recording their activity.",
                            "Participants can click the check-in button at checkpoints, and the system will determine if they have reached the checkpoint based on their current location.",
                            "Participants can view the route to the next checkpoint.",
                            "Participants can view their current ranking.",
                            "Participants can choose whether to join the leaderboard.",
                            "Participants can view their progress and time.",
                            "Participants can request help.",
                            "..."
                        ]
                    }
                },
                feedback: {
                    header: "Customer Feedback",
                    content: "The system is excellent, easy to navigate, and visually appealing. Clearly, a lot of thought has been put into programming, system aesthetics, messaging, and the language used. The added information when someone wants to withdraw is fantastic and really captures the fun and caring spirit of fundraising. The system looks very similar to existing apps, so I think walkers will find it easy to use. The system event has a leaderboard, which players can choose to join, which is a great option."
                },
                price: {
                    name: "Software House Award",
                    content: "This award is given annually to recognize software developed for real-world clients. Students compete in teams to develop the most effective software, following agile development processes and working closely with clients. This student was part of the winning team, recognized by the team’s client as a member of the winning team."
                }
            },
            textProcessing:{
                title: 'Text Processing',
                abstract: 'Using machine learning to build a text analysis system, text information extraction, information retrieval and text emotional semantic analysis'
            },
            dataDriven:{
                title: 'Checkerboard graph classifier',
                abstract: 'Build and evaluate a system to analyze noisy checkerboard plots and report pieces on each square using data-driven methods'
            },
            rentSystem:{
                title: 'Rental system by Swing',
                abstract: 'The system manages a series of short-term properties, with different roles having access to different data and will manage hotel bookings and reviews.'
            },
            haskell:{
                title: 'Card game based on Haskell',
                abstract: 'Code 8-off solitaire games and spider Solitaire games using Haskell language, and analyze their performance'
            },
            neteaseMusic:{
                title: 'Imitation NetEase cloud music',
                abstract: 'Using NetEase cloud music official API to build a mock NetEase cloud music web application'
            },
            eleme:{
                title: 'Delivery web application',
                abstract: 'Imitation Eleme delivery APP web side application'
            },
        },
        cat: {
            name: 'Paris',
            age: '4 ',
            age_title: 'Age',
            weight: '5.7 KG',
            weight_title: 'Weight',
            sex: 'Female',
            sex_title: 'Gender'
        },
        contact: {
            header: "Ready to Chat?",
            name_label: "Name:",
            email_label: "Email Address:",
            title_label: "Title:",
            message_label: "Message:",
            send_btn: "Send",
            notification:{
                success: "Brilliant! Thanks for the email, I will come back to you soon.",
                failed: "Unfortunately the email failed to send, please try again or manually drop me an email.",
            }
        }
    }
}