<template>
    <div class="mt-5 container-lg">
       <WorkHeader :title="$t('message.project.musicBuddy.title')" date="2024/08/24"/>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-12 p-0 m-0">
                <!--标题卡片-->
                <div class="card bg-light border-0 rounded-4 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <div class="d-flex align-items-center">
                                <div class="d-none d-md-block me-5 border border-1 rounded-5 shadow-sm ">
                                    <img src="@/assets/worksDetail/musicBuddy/icon.png" class="img-fluid rounded-3" style="width: 100px; height: 100px;"/>
                                </div>

                                <div>
                                    <h1 class="fw-bold">{{ $t('message.project.musicBuddy.app_name') }}</h1>
                                    <p class="text-muted">{{ $t('message.project.musicBuddy.title') }}</p>
                                </div>
                            </div>
                            <div class="d-none d-md-flex flex-wrap mt-3">
                                <div v-for="(word,index) in ['Node.js','Vue.js','Bootstrap', 'MongoDB']" :key="index" class="d-flex mb-2">
                                    <TagButton :tag="word" class="mx-1"></TagButton>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <img src="@/assets/worksDetail/musicBuddy/musicBuddy_matting.png" class="img-fluid">
                        </div>
                    </div>
                </div>
                <!--项目目标卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <h3 class="text-center red-bottom">{{$t('message.project.musicBuddy.aim.header')}}</h3>
                            <p class="mt-3">{{$t('message.project.musicBuddy.aim.content')}}</p>
                        </div>
                        <div class="col-12 col-lg-6 mt-2 mt-lg-0 d-flex justify-content-center">
                            <table>
                                <tr>
                                    <th>#</th>
                                    <th>{{$t('message.project.stack.type')}}</th>
                                    <th>{{$t('message.project.stack.stack')}}</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>{{$t('message.project.stack.front_end')}}</td>
                                    <td>Vue2; Bootstrap</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>{{$t('message.project.stack.back_end')}}</td>
                                    <td>Node.js、Express.js; Flask</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>{{$t('message.project.stack.api_testing')}}</td>
                                    <td>Postman</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>{{$t('message.project.stack.database')}}</td>
                                    <td>MongoDB</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>{{$t('message.project.stack.editor')}}</td>
                                    <td>WebStorm; VS Code; Spyder</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>{{$t('message.project.stack.version_control')}}</td>
                                    <td>GitHub</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <!--系统功能卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <h3 class="text-center red-bottom">{{$t('message.project.musicBuddy.feature.header')}}</h3>
                    <img src="@/assets/worksDetail/musicBuddy/features.png" class="img-fluid w-100">
                </div>
                <!--用户界面卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class=" mt-3">
                        <h3 class="red-bottom">用户界面</h3>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img src="@/assets/worksDetail/musicBuddy/recommendation.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.musicBuddy.interface.recommendation.header')}}</h3>
                            <p>{{$t('message.project.musicBuddy.interface.recommendation.content')}}</p>
                        </div>
                    </div>


                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.musicBuddy.interface.random_recommendation.header')}}</h3>
                            <p>{{$t('message.project.musicBuddy.interface.random_recommendation.content')}}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <img src="@/assets/worksDetail/musicBuddy/tuneSwipe.png" class="img-fluid">
                        </div>

                    </div>


                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6">
                            <img src="@/assets/worksDetail/musicBuddy/spotify.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center">
                            <h3 class="fw-bold">{{$t('message.project.musicBuddy.interface.integration.header')}}</h3>
                            <p>{{$t('message.project.musicBuddy.interface.integration.content')}}</p>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.musicBuddy.interface.metadata.header')}}</h3>
                            <p>{{$t('message.project.musicBuddy.interface.metadata.content')}}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <img src="@/assets/worksDetail/musicBuddy/metadata.png" class="img-fluid">
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6">
                            <img src="@/assets/worksDetail/musicBuddy/search.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center">
                            <h3 class="fw-bold">{{$t('message.project.musicBuddy.interface.search.header')}}</h3>
                            <p>{{$t('message.project.musicBuddy.interface.search.content')}}</p>
                        </div>

                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.musicBuddy.interface.tag.header')}}</h3>
                            <p>{{$t('message.project.musicBuddy.interface.tag.content')}}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <img src="@/assets/worksDetail/musicBuddy/tags.png" class="img-fluid">
                        </div>
                    </div>


                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6">
                            <img src="@/assets/worksDetail/musicBuddy/report.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center">
                            <h3 class="fw-bold">{{$t('message.project.musicBuddy.interface.report.header')}}</h3>
                            <p>{{$t('message.project.musicBuddy.interface.report.content')}}</p>
                        </div>

                    </div>
                </div>
                <!--用户反馈卡片-->
                <div class=" card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <h3 class="red-bottom">{{$t('message.project.musicBuddy.feedback.header')}}</h3>
                    <p class="text-muted">{{$t('message.project.musicBuddy.feedback.last_update')}} 20/08/2024</p>

                    <div class="card w-100 p-3 mt-3 rounded-3">
                        <h4 class="mb-5">{{$t('message.project.musicBuddy.feedback.exploration.header')}}</h4>
                        <bar-chart
                            :chart-data="[4.40, 4.53, 4.47, 4.40]"
                            :chart-labels="['Tags Filtering', 'Search Filtering', 'Metadata Visualization', 'Statistics and Report']"
                            :labels="'Average Score for Music Exploration'"
                            :show-legend="false"/>

                        <div class="row mt-3">
                            <ul class="ps-5 comment-list">
                                <li v-for="(comment, index) in $t('message.project.musicBuddy.feedback.exploration.comments')" :key="index">
                                    {{ comment }}
                                </li>
                            </ul>
                        </div>


                    </div>

                    <div class="card w-100 p-3 mt-3 rounded-3">
                        <h4 class="mb-5">{{$t('message.project.musicBuddy.feedback.recommendation.header')}}</h4>
                        <bar-chart
                            :chart-data="[3.46, 3.20, 3.20, 3.66, 4.33, 4.53, 3.40]"
                            :chart-labels="['Keyword Mode', 'Semantic mode', 'Topic mode', 'Weighted mode','Adjustment options', 'Random recommendation', 'Overall Recommendation']"
                            :labels="'Average Score for Music Exploration'"
                            :show-legend="false"/>

                        <div class="row mt-3">
                            <ul class="ps-5 comment-list">
                                <li v-for="(comment, index) in $t('message.project.musicBuddy.feedback.recommendation.comments')" :key="index">
                                    {{ comment }}
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div class="card w-100 p-3 mt-3 rounded-3">
                        <h4 class="mb-5">{{$t('message.project.musicBuddy.feedback.system.header')}}</h4>
                        <bar-chart
                            :chart-data="[4.73, 4.00]"
                            :chart-labels="['System interface', 'Overall assessment of the system']"
                            :labels="'Average Score for Music Exploration'"
                            :show-legend="false"/>

                        <div class="row mt-3">
                            <ul class="ps-5 comment-list">
                                <li v-for="(comment, index) in $t('message.project.musicBuddy.feedback.system.comments')" :key="index">
                                    {{ comment }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="card w-100 p-3 mt-3 rounded-3">
                        <h4 class="mb-5">{{$t('message.project.musicBuddy.feedback.part_of_system.header')}}</h4>
                        <bar-chart
                            :chart-data="[4.47, 3.68,4.47,4.44, 4.37]"
                            :chart-labels="['Music Exploration', 'Music Recommendation', 'Metadata Visualization', 'Statistic & Report', 'Overall System']"
                            :labels="'Average Score for Three Main Part of the System'"
                            :show-legend="false"/>

                    </div>

                </div>
                <!--Promo卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <img src="@/assets/worksDetail/musicBuddy/promo.png" class="img-fluid w-100">
                </div>


            </div>
        </div>
    </div>
       
</template>

<script>
import WorkHeader from '@/components/WorkHeader';
import BarChart from "@/components/BarChart.vue";
import TagButton from "@/components/TagButton.vue";

export default {
    components: {
        TagButton,
        BarChart,
        WorkHeader,
    },

};
</script>

<style lang="css" scoped>
.red-bottom {
    width: fit-content;
    border-bottom: 3px solid #FF6666;
}

table {
    border-collapse: collapse;
    width: 100%;
}
th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}
th {
    background-color: #f2f2f2;
}

li{
    list-style-type: circle;
}

.comment-list {
    column-count: 2; /* 将列表分成两列 */
    column-gap: 20px; /* 列之间的间距，可以根据需要调整 */
    padding-left: 0; /* 移除左侧内边距 */
    list-style: none; /* 去除默认的列表样式 */
}

.comment-list li {
    margin-bottom: 10px; /* 每个列表项的下边距，可以根据需要调整 */
}
</style>